
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { Field } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import MotorBreakdown from "@/components/reusable/policy-information/documents-uploads/MotorBreakdown.vue";

export default defineComponent({
  name: "underwriter-policy-certificate-upload",
  components: { Field, MotorBreakdown },
  props: {
    redirect: String,
    policyRoute: String,
    policiesRoute: String,
    clientsRoute: String,
    role: String,
  },
  data() {
    return {
      clients: [],
      policies: [],
      vehicles: [],
      policySummary: {},
      shortCode: "",
      displayTable: false,
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Upload Motor Certificate", [
        "Policies",
        "Policy Documentation",
      ]);
      MenuComponent.reinitialization();
    });
  },
  created() {
    // Get underwriters clients
    this.getClients();

    //Set page title
    document.title = "Policy Certificate Upload | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    // Get list of clients based on user roles
    getClients() {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${this.clientsRoute}`)
          .then(({ data }) => {
            // Assign data to variables
            this.clients = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getClientPolicies(event) {
      const route =
        this.role == variables.UNDERWRITER_USER_ROLE
          ? `${this.policiesRoute}/${event.target.value}/policies`
          : `${this.policiesRoute}/${event.target.value}/policies/MOT?withPolicyNumber=true`;

      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${route}`)
          .then(({ data }) => {
            // Assign data to variables
            this.policies = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getPolicy(event) {
      this.displayTable = false;
      const underwriteRole = variables.UNDERWRITER_USER_ROLE;

      const route =
        this.role == underwriteRole
          ? `${this.policyRoute}/${event.target.value}/details`
          : `${this.policyRoute}/${event.target.value}`;

      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${route}`)
          .then(({ data }) => {
            if (this.role == underwriteRole) {
              this.policySummary = data.data;
              this.vehicles = data.data.items;
              this.shortCode = data.data.businessTypeShortCode;
            } else {
              // Assign data to variables
              this.policySummary = data.data.policySummary;
              this.vehicles = data.data.items;
              this.shortCode = data.data.policySummary.businessTypeShortCode;
            }

            this.displayTable = true;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
  },
});
